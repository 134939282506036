import React, { useState } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

/**
 * @param {string} token
 */
const decodeCredential = (token) => {
    try {
        return jwtDecode(token);
    } catch (error) {
        console.error('Error decoding token:', error);
        return null;
    }
};

/**
 *
 * @param {{callback:Function}}} param0
 * @returns
 */
export default function Login({ callback = () => {} }) {
    return (
        <GoogleOAuthProvider clientId="667681040056-640oaj6c36a2cebhifnid0gho2l9ubol.apps.googleusercontent.com">
            <GoogleLogin
                onSuccess={(credentialResponse) => {
                    const response = decodeCredential(
                        credentialResponse?.credential
                    );
                    localStorage.setItem('User-Info', JSON.stringify(response));
                    callback(response);
                }}
                onError={() => {
                    console.log('Login Failed');
                }}
            />
        </GoogleOAuthProvider>
    );
}
