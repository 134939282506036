import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "./Button";
import Slide from "@mui/material/Slide";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Skeleton from "@mui/material/Skeleton";
import pandaNtfs from "../../assets/panda-ntfs.png";
import { useMobile } from "../../hooks/isMobile";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import "../../styles/payment.css";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#393939" : "#393939",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

export default function AlertDialog({ open, handleClose }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  const isMobile = useMobile();

  const currentDate = new Date();

  const timestamp = `${currentDate.getFullYear()}${padZero(
    currentDate.getMonth() + 1
  )}${padZero(currentDate.getDate())}${padZero(
    currentDate.getHours()
  )}${padZero(currentDate.getMinutes())}${padZero(
    currentDate.getSeconds()
  )}${padZero(currentDate.getMilliseconds())}`.slice(0, 16);

  const formattedDate = `${currentDate.getFullYear()}-${padZero(
    currentDate.getMonth() + 1
  )}-${padZero(currentDate.getDate())} ${padZero(
    currentDate.getHours()
  )}:${padZero(currentDate.getMinutes())}:${padZero(currentDate.getSeconds())}`;

  function padZero(num) {
    return num.toString().padStart(2, "0");
  }

  const [payment, setPayment] = React.useState("");

  const handleChange = (event) => {
    setPayment(event.target.value);
    if (event.target.value != "") {
      setAlert(false);
    } else {
      setAlert(true);
    }
  };

  const handleCloseWithReason = (event, reason) => {
    if (reason !== "backdropClick") {
      handleClose(event, reason);
    }
  };

  const [alert, setAlert] = useState(false);

  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    const handleImageLoad = () => {
      setImageLoaded(true);
    };

    const image = new Image();
    image.src = pandaNtfs; // 替换成你的图片链接
    image.onload = handleImageLoad;

    return () => {
      image.onload = null;
    };
  }, []);

  const price = 1254.0;

  const place_data = {
    price: price * 100,
    order_on: timestamp,
    pay_type: payment,
    return_url: "http://localhost:3000/",
  };

  const place = () => {
    if (!payment) {
      setAlert(true);
    } else {
      setLoading(true);
      axios({
        url: "http://vrpanda.test/api/pay/pay_nft",
        method: "post",
        data: place_data,
      })
        .then((res) => {
         if(res.data.code === 200) {
            setLoading(false);
            window.location.href = res.data.data.qr_code;
         }
          // console.log(res.data.code);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={handleCloseWithReason}
        aria-describedby="alert-dialog-slide-description"
        keepMounted
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={isMobile ? "xl" : "md"}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 999 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle>
          <div style={{ width: 200 }}>
            {imageLoaded ? (
              <img
                src={pandaNtfs} // 替换成你的图片链接
                alt="Your Image"
                style={{ width: isMobile ? "30vw" : "15vw", height: "auto" }}
              />
            ) : (
              <Skeleton variant="rectangular" />
            )}
          </div>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={isMobile ? 12 : 7}>
                <Item>
                  <p className="item-p">Room 26, 9/F, Core F</p>
                  <p className="item-p">Cyerport 3, 100 Cyberport Rd, HK</p>
                  <p className="item-p">
                    +852 3618 6104 , info@radiance.com.hk
                  </p>
                </Item>
              </Grid>
              <Grid item xs={isMobile ? 12 : 5}>
                <Item className="item-right">
                  <p className="item-p">Invoice# {timestamp}</p>
                  <p className="item-p">Date : {formattedDate}</p>
                  <p className="item-p">Author : Radiance</p>
                </Item>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  <FormControl sx={{ m: 0, minWidth: "100%" }} size="small">
                    <InputLabel id="demo-select-small-label">
                      Payment
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={payment}
                      label="Payment"
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={"Alipay"}>Alipay</MenuItem>
                      <MenuItem value={"Wechat"}>Wechat</MenuItem>
                      <MenuItem value={"CUP"}>UnionPay</MenuItem>
                      <MenuItem value={"CreditCard"}>CreditCard</MenuItem>
                      <MenuItem value={"Fps"}>Fps</MenuItem>
                    </Select>
                  </FormControl>
                  <Collapse in={alert}>
                    <Alert severity="warning">
                      Please select mode of payment
                    </Alert>
                  </Collapse>
                </Item>
              </Grid>

              <Grid item xs={12}>
                <Item>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>COMMODITY</TableCell>
                          <TableCell>PRICE</TableCell>
                          <TableCell>TOTAL</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>TEST NFT</TableCell>
                          <TableCell>${price.toFixed(2)}</TableCell>
                          <TableCell>${price.toFixed(2)}</TableCell>
                        </TableRow>

                        {/* 其他行... */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            className="payment-btn"
            width="45%"
            height="45px"
            child={
              <div>
                <p>Cancel</p>
              </div>
            }
            onClick={handleClose}
          ></Button>
          <Button
            width="45%"
            height="45px"
            // onClick={place}
            child={
              <div>
                <p>Sumbit</p>
              </div>
            }
          ></Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
