export const Colors = {
    "buttons":{
        "primary":"#FFFFFF",
        "secondary":"#82e4ed",
        "danger":"#f53d14",
        "succes":"#14f550"
    },
    "text":{
        "font-size":15
    }
}

