import { useState, useEffect } from "react";
import * as React from "react";

import { Link } from "react-router-dom";
import { useEthers, useEtherBalance } from "@usedapp/core";

import { VscAccount } from "react-icons/vsc";

import Login from "./auth/Login";
// import Logout from "./auth/Logout";
import styled from "styled-components";
import { FaWallet } from "react-icons/fa";
// import Modal from './base/Madal';
// import Button from '@mui/material/Button';

import PropTypes from "prop-types";
// import Button from '@mui/material/Button';
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { googleLogout } from '@react-oauth/google';
import "../styles/header.css";

import { createTheme, ThemeProvider } from "@mui/material/styles";

export default function Header() {
  const { activateBrowserWallet, account } = useEthers();
  const etherBalance = useEtherBalance(account);

  const handleWallet = () => {
    activateBrowserWallet();
  };

  const handleClickModal = () => {
    if (!userInfo) {
      setModalOpen(true);
    }
  };

  const handleDisplayOrHiddenUserlist = () => {};

  const WalletButton = () => {
    return (
      <button id="connect-wallet" onClick={handleWallet}>
        {!account ? "Connect Wallet" : account}
      </button>
    );
  };

  const LogoutButton = () => {
    return (
      <button id="connect-wallet" onClick={Logout}>
        {"Logout" }
      </button>
    );
  };



  const [modalOpen, setModalOpen] = useState(false);
  const [userListOpen, setUserListOpenOpen] = useState(true);
  const [userInfo, setUserInfo] = useState(null);

  const theme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const handleClose = (value) => {
    setModalOpen(false);
  };

  const SimpleDialog = (props) => {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
      onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
      onClose(value);
    };

    return (
      <ThemeProvider theme={theme}>
        <Dialog onClose={handleClose} open={open}>
          <DialogTitle> Connect to NFT Room</DialogTitle>
          <List sx={{ pt: 0 }}>
            <ListItem disableGutters key="wallter" className="list-item">
              <WalletButton />
            </ListItem>
            <ListItem disableGutters key="or" className="list-item">
              or
            </ListItem>
            <ListItem disableGutters key="google" className="list-item">
              <Login callback={setUserInfo} />
            </ListItem>
          </List>
        </Dialog>
      </ThemeProvider>
    );
  };

  SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
  };

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const Logout = () => {
    googleLogout();
    setUserInfo(null);
    localStorage.removeItem('User-Info');
  }

  useEffect(() => {
    // just demo
    const localUserInfo = localStorage.getItem("User-Info");

    if (localUserInfo) {
      setUserInfo(JSON.parse(localUserInfo));
    }
  }, []);

  useEffect(() => {
    if (userInfo?.email_verified) {
      localStorage.setItem("User-Info", JSON.stringify(userInfo));

      setModalOpen(false);
    }
  }, [userInfo]);

  return (
    <>
      <div id="header">
        <Link to="/" id="logo">
          NFT Room
        </Link>

        <TabList className="tab-list">
          <Link to="/hunting" id="link">Start Hunting</Link>
          {/* <Anchor>Craft NFT</Anchor> */}

          <Flex>

            {!userInfo ? (
              <Button onClick={handleClickModal}>
                <FaWallet color="black" size={"20px"} /> Login
              </Button>
             
            ) : (
              <></>
            )}
            {userInfo ? (
              <ThemeProvider theme={theme}>
                <Box sx={{ flexGrow: 0 }}>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar alt="Remy Sharp" src={userInfo?.picture} />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <MenuItem key="wallet" onClick={handleCloseUserMenu}>
                      <Typography textAlign="center">
                        <WalletButton />
                      </Typography>
                    </MenuItem>

                    <MenuItem key="logout" onClick={handleCloseUserMenu}>
                      <Typography textAlign="center">
                        <LogoutButton />
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              </ThemeProvider>
            ) : (
              <></>
            )}
          </Flex>
        </TabList>
      </div>

      <SimpleDialog open={modalOpen} onClose={handleClose} />

    </>
  );
}

const IconImage = styled.img`
  width: 40px;
  height: 40px;
`;

const TabList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  column-gap: 40px;
  font-weight: 700;
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
`;

const Button = styled.button`
  display: flex;
  column-gap: 4px;
  cursor: pointer;
  justify-content: center;
  padding: ${(props) => (props?.isLogin ? "0px" : "0 12px")};
  overflow: ${(props) => (props?.isLogin ? "hidden" : "visible")};
  align-items: center;
  border-radius: 12px;
  position: relative;
  height: 40px;
  min-width: 40px;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.5px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: rgba(255, 255, 255);
  opacity: 0.8;
  color: rgb(0, 0, 0);
  backdrop-filter: blur(20px);
  transition: opacity 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s;

  &:hover {
    opacity: 1;
    transition: opacity 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s;
  }
`;

const Anchor = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border: none;
  cursor: pointer;
`;
