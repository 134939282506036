import react ,{ useEffect, useState } from 'react' ;
import CardList from "../components/CardList-flex";
import { exploreList } from "../constants/MockupData";
import '../styles/Explore.css';
import Header from "../components/Header";
import Search from "../components/Search";

//models
import AntiqueCamera from '../assets/models/AntiqueCamera.glb';
import BarramundiFish from '../assets/models/BarramundiFish.glb';
import Avocado from '../assets/models/Avocado.glb';
import CyberCar from '../assets/models/CyberCar.glb';



// url数组
const urls = [
  CyberCar,
  AntiqueCamera,
  BarramundiFish,
  Avocado,
  "https://images.unsplash.com/photo-1614812513172-567d2fe96a75?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
  "https://images.unsplash.com/photo-1541661538396-53ba2d051eed?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=627&q=80",
  "https://images.unsplash.com/photo-1573221566340-81bdde00e00b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=735&q=80",
  "https://images.unsplash.com/flagged/photo-1567934150921-7632371abb32?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
  "https://images.unsplash.com/photo-1608085575984-d61d8335340e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
  "https://images.unsplash.com/photo-1638913970675-b5ec36292665?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1332&q=80",
]

function generateData(ids) {
  ids = ids.prefered_items
  return ids.map(id => ({
    name: `NFT #${id}`,
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    src: urls[Math.floor(Math.random() * urls.length)],
  }))
}

const Hunting = () => {

  const [huntingList, setHuntingList] = useState([]);

  useEffect(() => {
    const loadHuntingList = async () => {
     
      const data = {
        prefered_items: [3,39,16,21,22,29,2,5,9,19,32,6,8,25,35,23,40,18,12,4]
      };

      // 生成json数据
      const generatedList = generateData(data);
      setHuntingList(generatedList);
    };
    loadHuntingList();
  }, []);

  return (
    <div id="explore">
      <Header />
      <Search/>
      <div id="list-container">
        <CardList list={huntingList} />
      </div>
    </div>
  );
};

export default Hunting;
